import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';
export default class OrderService{
    key =localStorage.getItem('apiKey')
    async getOrderHistory(start = 0 ,length = 10){
        const response = await fetch(`${BASE_URL}order/order_history?start=${start}&length=${length}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }
}